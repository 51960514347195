<template>
	<div style="background-color: #fff;margin: 10px;border-radius: 3px;">
		<div style="position: sticky;top:0;z-index: 999;background-color: #fff;padding-bottom: 1px;border-bottom: 1px solid #ebeef5;padding-top: 10px;">
			<!--带折叠的搜索start-->
			<div>
				<el-form size="mini" style="display: flex;flex-wrap: wrap;padding-left: 8px;" :style="{'height':isShowCollapse?'auto':'50px'}" ref="searchForm" :model="searchForm" inline label-width="120px">

					<!--收起时显示的搜索项start 4个搜索项为一行-->
					<div v-show="!isShowCollapse" style="width: 80%;display: flex;">

					

						<el-form-item label="昵称" style="width: 23%;display: flex;">
							<el-input v-model="searchForm.name" placeholder="昵称" clearable size="mini" />
						</el-form-item>
					
						
					</div>
					<!--收起时显示的搜索项end-->

					<!--展开时显示的搜索项start 4个搜索项为一行-->
					<div v-show="isShowCollapse" style="display: flex;flex-wrap: wrap;width: 100%;">
						
						<el-form-item label="昵称" style="width: 20%;display: flex;" label-width="100px">
							<el-input v-model="searchForm.name" placeholder="昵称" clearable size="mini" />
						</el-form-item>
					

						
					</div>
					<!--展开时显示的搜索项end-->

					<div :style="{'width':isShowCollapse?'100%':'13%','justify-content': isShowCollapse?'flex-end':'center' }" style="height: 30px; display: flex;margin: 0;margin-bottom: 10px;">
						<div style="flex-shrink: 0;margin-right: 20px;display: flex;justify-content: center;align-items: center;cursor:pointer;color: #9A7E9C;font-size: 14px;line-height: 20px; display: flex;margin-left: 20px;" @click="isShowCollapseF()">
							<span v-if="!isShowCollapse">展开</span>
							<span v-else>收起</span>
							<span style="margin-left: 5px;font-size: 20px;line-height: 20px;" :style="{transform:isShowCollapse?'rotate(180deg)':'rotate(0deg)'}">∨</span>
						</div>
						<el-button round style="margin-right: 10px;" @click="handleSearch" type="primary" icon="el-icon-search" size="small">搜索</el-button>
						<el-button round @click="handleReset" size="small">重置</el-button>
						<div v-if="isShowCollapse" style="width: 70px;"></div>
					</div>


				</el-form>
			</div>
			<!--带折叠的搜索end-->

		
		</div>





		<!--表格start :height="tableHeight+'px'"-->
		<el-table :height="tableHeight" :show-header="false" ref="table" :data="tableData" style="width: 100%;background-color: #fff;margin: 5px;">

			<el-table-column style="width: 100%;">
				<template slot-scope="{ row, index }">
					<div class="tableTem" style="display: flex;margin-left: 20px;">
						<div class="avatar">

							<!--用户图片start-->
							<div style="text-align: center;" class="dis">
								<img :src="row.img" style="width:70px;height:70px;cursor: pointer;" @click="handlePreView(row.img)">
								<!-- <div class="titleFont">主图</div> -->
							</div>
							<!--用户图片end-->

							<div style="width: 180px;">

								<!--头像右侧一个列表项start-->
								<div style="display: flex;">
									<span class="titleFont" style="width: 70px;text-align: right;">id：</span>
									<el-tooltip :content="row.id+''" placement="right">
										<div class="dian dianL">{{row.id}}</div>
									</el-tooltip>
								</div>
								<!--头像右侧一个列表项end-->
								<div style="display: flex;">
									<span class="titleFont" style="width: 70px;text-align: right;">昵称：</span>
									<el-tooltip :content="row.name" placement="right">
										<div class="dian dianL">{{row.name}}</div>
									</el-tooltip>
								</div>

								<div style="display: flex;">
									<span class="titleFont" style="width: 70px;text-align: right;">性别：</span>
									<el-tooltip :content="row.sex?(row.sex==1?'男':'女') :'未知' " placement="right">
										<div class="dian dianL">{{row.sex?(row.sex==1?'男':'女') :'未知' }}</div>
									</el-tooltip>
								</div>

								<div style="display: flex;">
									<span class="titleFont" style="width: 70px;text-align: right;">手机号：</span>
									<el-tooltip :content="row.mobile+''" placement="right">
										<div class="dian dianL">{{row.mobile?row.mobile:'无'}}</div>
									</el-tooltip>
								</div>

	

							</div>
						</div>

						<!-- height: 50px; -->
						<ul  :style="{'width':isMobile?mobileWidth+'px':'','overflow-x':isMobile?'auto':'','overflow-y':isMobile?'hidden':'','flex-wrap':isMobile?'nowrap':''}">



							<!-- 一个方块列表项start-->
							<!-- <li >
								<div class="content">
									<img :src="row.store.image" style="width:50px;cursor: pointer;" @click="handlePreView(row.store.image)">
								</div>
								<div class="titleFont dian">门店图片</div>
							</li> -->
							<!-- 一个方块列表项end-->

							



							<li>
								<el-tooltip :content="row.date+''" placement="right">

									<div class="content dis">
										{{row.date?row.date:'无' | timeBreak2}}
									</div>
								</el-tooltip>
								<div class="titleFont dian">注册日期</div>
							</li>

						</ul>


						<div style="width: 100px;display: flex;justify-content: start;align-items: center;">
							<el-dropdown trigger="hover" style="width: 80px;">
								<el-button size="mini" round type="primary">
									操作<i class="el-icon-arrow-down el-icon--right"></i>
								</el-button>
								<el-dropdown-menu slot="dropdown">

									<el-dropdown-item>
										<el-button type="success" size="mini" round @click="showAddrList(row)">收货地址
										</el-button>
									</el-dropdown-item>


								</el-dropdown-menu>
							</el-dropdown>

						</div>

					</div>

				</template>
			</el-table-column>
		</el-table>
		<!--表格end-->

		<!--分页start-->
		<el-row type="flex" justify="end" align="middle" class="page" style="margin-top: 10px;margin-right: 50px;padding-bottom: 10px;">
			<el-pagination background layout="prev, pager, next" :current-page="pageNumber" :page-size="pageSize" :total="total" @current-change="changePage"></el-pagination>
			<span style="font-size: 13px;color: #999;">共 {{total}} 条</span>
		</el-row>
		<!--表格end-->

		<!--浏览图片的弹窗start-->
		<el-dialog width="400px" footer-hide title="预览" :visible.sync="isShowPreImg">
			<img :src="preImgUrl" style="width: 100%">
		</el-dialog>
		<!--浏览图片的弹窗end-->


		<!-- 全屏透明的loading加载start -->
		<div id="pageLoading" v-if="loading" style="position: fixed;top:0;left: 0;width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;z-index: 999999;">
			<i class="el-icon-loading" style="font-size: 25px;"></i>
		</div>
		<!-- 全屏透明的loading加载end -->
		
		
		<!-- 用户的收货地址列表start -->
		<el-dialog title="收货地址" :visible.sync="isShowAddrPop"  width="60%">
		  <el-table :data="addrList">
		    <el-table-column property="date" label="日期" width="200"></el-table-column>
		    <el-table-column property="name" label="收货人" width="200"></el-table-column>
		    <el-table-column property="mobile" label="手机号" width="200"></el-table-column>
			<el-table-column property="addr" label="地址"></el-table-column>
		  </el-table>
		</el-dialog>
		<!-- 用户的收货地址列表end -->
		
	</div>
</template>

<script>
	import {
		ajax
	} from '../../util/ajax.js';

	export default {
		name: '',
		components: {
		
		},
		data() {
			return {
				isMobile: window.isMobile,
				mobileWidth: window.mobileWidth + 100,
				
				
				rowGoods_id: '', //列表项的商品id

				isShowCollapse: false, //是否展开搜索项
				searchForm: {
					

				},

				isShowPreImg: false, //是否显示浏览图片的弹窗
				preImgUrl: '', //浏览图片弹窗的图片地址


				tableHeight: 0, //表格高度
				loading: false,
				tableData: [], //表格数据
				pageNumber: 1, // 当前页数
				pageSize: 10, // 页面大小
				total: 0,

				isShowAddrPop:false,//是否显示地址列表弹窗
				addrList:[],//地址列表


			}
		},

		filters: {
			//把 2021-07-30 11:07:36 格式为 07-30
			//								11:07
			//使用格式 <p>{{row.stateChangeTime | timeBreak(0)}}</p>    <p>{{row.stateChangeTime | timeBreak(1)}}</p> 
			timeBreak(time, type, from) {
				if (time) {
					return type == 0 ? (from == undefined ? time.substr(5, 5) : time.substr(0, 10)) : (type == 2 ? time
					.substr(0, 10) : (from == undefined ? time.substr(11, 5) : time.substr(11, 8)));
				} else {
					return time;
				}
			},

			timeBreak2(val) {
				
				let v = val.split(' ')[0];

				return v;
			},

		},
		created() {
			this.getTableHeight();
		},
		mounted() {

			//挂载window.onresize事件(动态设置table高度)
			let _this = this;
			window.onresize = () => {
				if (_this.resizeFlag) {
					clearTimeout(_this.resizeFlag);
				}
				_this.resizeFlag = setTimeout(() => {
					_this.getTableHeight();
					_this.resizeFlag = null;
				}, 100);
			};

			this.initData();
		},

		methods: {
		
			//初始化表格数据
			async initData() {
				this.loading = true
				var searchForm = this.searchForm
				
				if (searchForm.name === "") { //select未选中为空字符串
					searchForm.name = null; //为null后就不会作为http请求参数了带给后端了
				}
				
				let param = {

					...searchForm,//搜索的 
					
					count: this.pageSize,
					page: this.pageNumber
				}
				
		
				
				let ress = await ajax('/getUserList', 'POST',param);
				this.loading = false;
				let res = ress.data.data;

				this.total = res.total;
				this.tableData = res.list;
			},
			
			
			//查看详情
			async showAddrList(row){
				this.addrList = [];
				this.loading = true
				let ress = await ajax('/getUserAddrListByUserId', 'POST',{id:row.id});
				this.loading = false;
				let res = ress.data.data;
				this.addrList = res.list;
				this.isShowAddrPop = true;
			},

			//计算table高度(动态设置table高度)
			getTableHeight() {
				let tableH = 200; //距离页面下方的高度,值越大表格高度越小
				let tableHeightDetil = window.innerHeight - tableH;
				if (tableHeightDetil <= 300) {
					this.tableHeight = 300;
				} else {
					this.tableHeight = window.innerHeight - tableH;
				}
			},


			//展开搜索项
			isShowCollapseF() {
				this.isShowCollapse = !this.isShowCollapse;
			},
			//翻页
			changePage(pageNumber) {
				this.tableData=[];//这种可以让滚动条回到顶部
				this.pageNumber = pageNumber
				this.initData()
			},

			//点击头像
			handlePreView(url) {
				this.preImgUrl = url
				this.isShowPreImg = true
			},





			//搜索
			handleSearch() {
				this.pageNumber = 1;
				this.initData()
			},

			//重置
			handleReset() {
				this.$refs.searchForm.resetFields();
				this.searchForm={};
			
				this.pageNumber = 1
				this.pageSize = 10
				this.tableData = []; //表格数据修改为空
				this.initData()
			},






		}


	}
</script>

<style scoped>
	.tableTem ul {
		width: 100%;
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;

	}

	.tableTem ul li {
		list-style: none;

		width: 110px;
		min-height: 60px;
		border-left: 1px solid #ededed;
		border-bottom: 1px solid #ededed;
		margin-bottom: -1px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding: 3px;


	}

	.tableTem .content {

		padding-top: 3px;
		font-size: 14px;
		color: #2d8cf0;
		font-weight: 600;
	}

	.tableTem .avatar img {
		border-radius: 10px;
		width: 60px;
		height: 60px;
	}

	.tableTem .avatar {

		padding-right: 20px;
		display: flex;
		justify-content: center;

	}

	.tableTem .dian {
		text-align: center;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100px;
	}

	.tableTem .dianL {
		text-align: left;
		font-size: 13px;
		color: #333;
	}


	.titleFont {
		font-size: 12px !important;
		flex-shrink: 0;
		line-height: 25px;
		color: rgb(136, 136, 136);
	}





	.sortLine {
		display: flex;
		align-items: center;
	}

	.sortItem {
		
		height: 100%;
		color: rgb(156, 156, 156);
		text-align: center;
		line-height: 30px;
		cursor: pointer;
		min-width: 80px;
	}

	.sortItem:hover {
		background-color: #119744 !important;
		color: #fff !important;
	}

	.sortLine .sortItem:last-child {
		border-right: none;
	}

	>>>.el-table tbody tr:hover>td {
		background-color: #ffffff !important
	}


	>>>.el-icon-arrow-down {
		font-size: 12px;
	}

	>>>.demonstration {
		display: block;
		color: #8492a6;
		font-size: 14px;
		margin-bottom: 20px;
	}

	>>>.el-dropdown-link {
		cursor: pointer;
		color: #409EFF;
	}

	>>>.el-table td {
		padding: 10px 0 !important;
	}
</style>
